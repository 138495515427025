import { useState } from "react";

export function useToast({ listId, activeTeamAccountId, toastType }) {
  const TOAST_STORAGE_KEY = "toastStates";
  const toastId = listId
    ? `list_${listId}_team_${activeTeamAccountId}_type_${toastType}`
    : `team_${activeTeamAccountId}_type_${toastType}`;

  const [showToast, setShowToast] = useState(() => {
    // eslint-disable-next-line no-undef
    const storedToasts = localStorage.getItem(TOAST_STORAGE_KEY);
    const toastStates = storedToasts ? JSON.parse(storedToasts) : {};
    return !toastStates[toastId]; // Show toast if this ID doesn't exist in storage
  });

  const handleShowToast = () => {
    // eslint-disable-next-line no-undef
    const storedToasts = localStorage.getItem(TOAST_STORAGE_KEY);
    const toastStates = storedToasts ? JSON.parse(storedToasts) : {};

    if (showToast) {
      setShowToast(false);
      toastStates[toastId] = true;
    } else {
      setShowToast(true);
      delete toastStates[toastId];
    }

    // eslint-disable-next-line no-undef
    localStorage.setItem(TOAST_STORAGE_KEY, JSON.stringify(toastStates));
  };

  return { showToast, handleShowToast };
}
